(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AddressController = function () {
    function AddressController(loadingController, options) {
        _classCallCheck(this, AddressController);

        this.loadingController = loadingController;

        this.loadDefaults(options);

        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(AddressController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.addressData = window.addressData || {};
            this.options = {
                'initValidator': function initValidator() {},
                'mode': 'shipping',
                'billingAddressRadios': null,
                'updateRates': false,
                'form': null,
                'addressSelect': null,
                'postcode': '#postcode',
                'addressFields': {
                    'company': '#company',
                    'firstName': '#firstname',
                    'lastName': '#lastname',
                    'address1': '#address1',
                    'address2': '#address2',
                    'postcode': '#postcode',
                    'city': '#city',
                    'state': '#region_id',
                    'stateText': '#region',
                    'country': '#country_id',
                    'phone': '#phone'
                },
                'emptyAddress': {
                    "firstname": "",
                    "lastname": "",
                    "company": "",
                    "city": "",
                    "country_id": "US",
                    "region": "",
                    "postcode": "",
                    "telephone": "",
                    "region_id": "",
                    "street": " \n "
                },
                'shippingMethods': '#shipping-rates',
                'addressFieldset': '#address',
                'rateLoadingSpinner': '.ajax-loader'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            var _this = this;

            this.form = (0, _jQuery2["default"])(this.options.form);
            this.zipCodeField = this.form.find(this.options.postcode);
            this.addressSelect = this.form.find(this.options.addressSelect);
            this.addressFieldset = (0, _jQuery2["default"])(this.options.addressFieldset);

            if (this.options.billingAddressRadios) {
                this.billingAddressRadios = (0, _jQuery2["default"])(this.options.billingAddressRadios);
            }

            this.addressFields = {};
            Object.keys(this.options.addressFields).forEach(function (key) {
                var val = _this.options.addressFields[key];
                _this.addressFields[key] = (0, _jQuery2["default"])(val);
            });

            if (this.options.updateRates) {
                this.shippingMethods = (0, _jQuery2["default"])(this.options.shippingMethods);
            }
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.form.find(this.options.addressFields.state + ', ' + this.options.addressFields.country).on('change', '', this.revalidateZip.bind(this));
            this.addressSelect.on('change', this.addressSelectChanged.bind(this));
            this.addressFieldset.delegate('input, select, textarea', 'change', this.somethingChanged.bind(this));
        }
    }, {
        key: "init",
        value: function init() {
            this.options.initValidator.call(this);
            this.maRegionUpdater = new RegionUpdater(this.options.addressFields.country.replace('#', ''), this.options.addressFields.stateText.replace('#', ''), this.options.addressFields.state.replace('#', ''), window.regionJson, undefined, this.options.mode + ':postcode');

            this.addressSelectChanged();
            this.somethingChanged();
        }
    }, {
        key: "revalidateZip",
        value: function revalidateZip() {
            if (this.addressFields.country.val() == 'US') {
                this.zipCodeField.removeData("previousValue");
                this.zipCodeField.valid();
            }
        }
    }, {
        key: "revalidateAddress",
        value: function revalidateAddress() {
            this.zipCodeField.removeData("previousValue");
            var validator = this.form.validate();
            validator.form();
        }
    }, {
        key: "addressSelectChanged",
        value: function addressSelectChanged() {
            if (this.addressSelect.length > 0) {
                var addressId = this.addressSelect.val();
                if (this.addressData[addressId]) {
                    this.updateAddressFields(this.addressData[addressId]);
                } else {
                    this.updateAddressFields(this.options.emptyAddress);
                }
            }
        }
    }, {
        key: "updateAddressFields",
        value: function updateAddressFields(address) {
            var street = address.street.split("\n");

            this.addressFields.company.val(('' + address.company).trim());
            this.addressFields.firstName.val(('' + address.firstname).trim());
            this.addressFields.lastName.val(('' + address.lastname).trim());
            this.addressFields.address1.val(street[0].trim());
            if (street[1]) {
                this.addressFields.address2.val(street[1].trim());
            }

            this.addressFields.postcode.val(('' + address.postcode).trim());
            this.addressFields.city.val(('' + address.city).trim());
            this.addressFields.country.val(('' + address.country_id).trim());
            this.addressFields.phone.val(('' + address.telephone).trim());

            if (this.maRegionUpdater) {
                this.maRegionUpdater.update();
            }

            if (parseInt(address.region_id, 10) > 0) {
                this.addressFields.state.val(('' + address.region_id).trim());
            } else {
                this.addressFields.stateText.val(('' + address.region).trim());
            }

            this.revalidateAddress();
        }
    }, {
        key: "somethingChanged",
        value: function somethingChanged() {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            this.debounceTimer = window.setTimeout(this.debouncedSomethingChanged.bind(this), 250);
        }
    }, {
        key: "debouncedSomethingChanged",
        value: function debouncedSomethingChanged() {
            var stateField = this.getProperStateField();

            this.loadingController.setLoadingText(this.getLoadingText());

            if (this.addressFields.postcode.val() == '' || stateField.val() == '') return;
            if (!this.allRequiredFieldsValid()) return;

            this.loadingController.show(this.getLoadingText());
            this.loadingController.loaderContainer.find('.loading .spinner').css({ 'visibility': 'visible' });

            if (this.lastAjaxRequest) {
                this.lastAjaxRequest.abort();
            }

            this.lastAjaxRequest = _jQuery2["default"].ajax(this.addressFieldset.attr('data-ajax-url'), {
                'data': this.form.serializeObject(),
                'cache': false,
                'method': 'POST',
                'success': this.updateComplete.bind(this)
            });
        }
    }, {
        key: "allRequiredFieldsValid",
        value: function allRequiredFieldsValid() {
            if (!this.addressFields.postcode.hasClass('valid')) {
                this.addressFields.postcode.valid();
                return false;
            }

            var stateField = this.getProperStateField();

            return stateField.valid() && this.addressFields.country.valid();
        }
    }, {
        key: "getBillingAddressMode",
        value: function getBillingAddressMode() {
            if (this.billingAddressRadios.filter(':checked').val() == '1') {
                return 'shipping';
            }

            return 'billing';
        }
    }, {
        key: "updateComplete",
        value: function updateComplete(resp) {
            if (resp.status == 'ok') {
                if (this.options.updateRates) {
                    this.shippingMethods.html(resp.shippingMethods);
                    this.shippingMethods.trigger('shippingMethodsUpdated');
                }

                this.loadingController.hide();
                if (this.options.mode == 'billing' && this.getBillingAddressMode() == 'billing') {
                    this.form.trigger('unlAddressChange', this.getStripeAddress());
                }
            } else {
                this.loadingController.setLoadingText(resp.message);
            }
            this.loadingController.loaderContainer.find('.loading .spinner').css({ 'visibility': 'hidden' });
        }
    }, {
        key: "getStripeAddress",
        value: function getStripeAddress() {
            var serializedObj = this.form.serializeObject();
            var region = "";
            if (serializedObj.billing && serializedObj.billing.region_id) {
                region = this.addressFields.state.find('option[value=' + serializedObj.billing.region_id + ']').text();
            } else if (serializedObj.billing) {
                region = serializedObj.billing.region;
            }

            return {
                'name': serializedObj.billing.firstname + ' ' + serializedObj.billing.lastname,
                'address_line1': serializedObj.billing.street[0],
                'address_line2': serializedObj.billing.street[1],
                'address_city': serializedObj.billing.city,
                'address_state': region,
                'address_zip': serializedObj.billing.postcode,
                'address_country': serializedObj.billing.country_id
            };
        }
    }, {
        key: "getLoadingText",
        value: function getLoadingText() {
            if (this.addressFields.postcode.val() == '' || this.addressFields.state.val() == '' || !this.addressFields.postcode.valid()) {
                return 'Please enter zip, state and country.';
            }

            return 'The best shipping methods will be calculated';
        }
    }, {
        key: "getProperStateField",
        value: function getProperStateField() {
            var stateField = this.addressFields.state;
            if (stateField.css('display') == 'none') {
                stateField = this.addressFields.stateText;
            }

            return stateField;
        }
    }]);

    return AddressController;
}();

exports["default"] = AddressController;

},{"../lib/jQuery":12}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoadingController = function () {
    function LoadingController(options) {
        _classCallCheck(this, LoadingController);

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();
    }

    _createClass(LoadingController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'form': '',
                'loaderContainer': '#shipping-rates',
                'loaderSelector': '.ajax-loader',
                'statusText': 'span',
                'defaultLoadingText': 'Loading ...',
                'submitButton': '#shipping-submit'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.loaderContainer = (0, _jQuery2["default"])(this.options.loaderContainer);
            this.submitButton = (0, _jQuery2["default"])(this.options.submitButton);
            this.form = (0, _jQuery2["default"])(this.options.form);
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.form.on('submit', this.formSubmit.bind(this));
        }
    }, {
        key: "setLoadingText",
        value: function setLoadingText(message) {
            var loaderEl = this.loaderContainer.find(this.options.loaderSelector + ' ' + this.options.statusText);
            loaderEl.text(message);
        }
    }, {
        key: "show",
        value: function show(message) {
            this.disableSubmit();
            message = message || this.options.defaultLoadingText;

            this.setLoadingText(message);
            this.loaderContainer.find(this.options.loaderSelector).fadeIn();
        }
    }, {
        key: "hide",
        value: function hide() {
            if (this.loaderContainer.find(this.options.loaderSelector).hasClass('show')) return;

            this.enableSubmit();
            this.loaderContainer.find(this.options.loaderSelector).fadeOut();
        }
    }, {
        key: "enableSubmit",
        value: function enableSubmit() {
            this.submitButton.prop('disabled', false);
            this.submitButton.text(this.submitButton.data('nexttext'));
        }
    }, {
        key: "disableSubmit",
        value: function disableSubmit(buttonText) {
            buttonText = buttonText || this.submitButton.data('loadingtext');
            this.submitButton.prop('disabled', true);
            this.submitButton.text(buttonText);
        }
    }, {
        key: "formSubmit",
        value: function formSubmit() {
            if (this.form.valid()) {
                this.disableSubmit(this.submitButton.data('nextsteploadingtext'));
            }
        }
    }]);

    return LoadingController;
}();

exports["default"] = LoadingController;

},{"../lib/jQuery":12}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoginController = function () {
    function LoginController(options) {
        _classCallCheck(this, LoginController);

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(LoginController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'email': '#customer-email',
                'password': '#customer-password',
                'loginButton': '#customer-login-submit'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.email = (0, _jQuery2["default"])(this.options.email);
            this.password = (0, _jQuery2["default"])(this.options.password);
            this.btn = (0, _jQuery2["default"])(this.options.loginButton);
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.btn.on('click touchstart', this.login.bind(this));
        }
    }, {
        key: "init",
        value: function init() {}
    }, {
        key: "login",
        value: function login() {
            _jQuery2["default"].ajax(this.btn.data('login-url'), {
                'data': { 'email': this.email.val(), 'password': this.password.val() },
                'cache': false,
                'method': 'POST',
                'success': this.loginComplete.bind(this)
            });
        }
    }, {
        key: "loginComplete",
        value: function loginComplete(resp) {
            if (resp.status == 'ok') {
                window.location.reload();
            } else {
                alert(resp.message);
            }
        }
    }]);

    return LoginController;
}();

exports["default"] = LoginController;

},{"../lib/jQuery":12}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

var _AddressController = require("./AddressController");

var _AddressController2 = _interopRequireDefault(_AddressController);

var _LoadingController = require("./LoadingController");

var _LoadingController2 = _interopRequireDefault(_LoadingController);

var _PaymentMethodController = require("./PaymentMethodController");

var _PaymentMethodController2 = _interopRequireDefault(_PaymentMethodController);

require("../lib/StripeValidators");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaymentController = function () {
    function PaymentController(options) {
        _classCallCheck(this, PaymentController);

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(PaymentController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'form': '#payment-form',
                'billingAddressRadios': 'input[name="billing[same_as_shipping]"]',
                'billingAddressForm': '#billing-address-container'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.form = (0, _jQuery2["default"])(this.options.form);
            this.billingAddressRadios = (0, _jQuery2["default"])(this.options.billingAddressRadios);
            this.billingAddressForm = (0, _jQuery2["default"])(this.options.billingAddressForm);
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.billingAddressRadios.on('change', this.billingAddressSwitchChanged.bind(this));
            this.form.on('unlAddressChange', this.addressChanged.bind(this));
        }
    }, {
        key: "init",
        value: function init() {
            Stripe.setPublishableKey(stripePublicKey);

            var loadingController = new _LoadingController2["default"]({ 'form': this.options.form, 'submitButton': '#payment-submit' });
            var pmc = this.paymentMethodController = new _PaymentMethodController2["default"](loadingController);

            this.addressController = new _AddressController2["default"](loadingController, {
                'form': '#payment-form',
                'addressSelect': '#billing-address-select',
                'updateRates': false,
                'mode': 'billing',
                'billingAddressRadios': this.options.billingAddressRadios,
                'addressFields': {
                    'country': '#billing-country_id'
                },
                'initValidator': function initValidator() {
                    _jQuery2["default"].validator.setDefaults({
                        'onkeyup': false
                    });

                    var validateOptions = {
                        'rules': {
                            'login[password]': {
                                required: '#customer-has-account:checked'
                            }
                        }
                    };

                    validateOptions.rules['billing[postcode]'] = {
                        'required': function () {
                            return this.addressFields.country.val() == 'US';
                        }.bind(this),
                        'minlength': 3,
                        'remote': {
                            'url': this.addressFields.postcode.attr('data-validation-url'),
                            'type': 'post',
                            'data': {
                                'state': function () {
                                    return this.addressFields.state.val();
                                }.bind(this),
                                'country': function () {
                                    return this.addressFields.country.val();
                                }.bind(this)
                            },
                            complete: function () {
                                this.somethingChanged();
                            }.bind(this)
                        }
                    };

                    validateOptions.rules[pmc.options.ccFields.number] = {
                        'required': true,
                        'stripeCC': true
                    };

                    validateOptions.rules[pmc.options.ccFields.cvv] = {
                        'required': true,
                        'stripeCVV': true
                    };

                    validateOptions.rules[pmc.options.ccFields.month] = {
                        'required': function () {
                            return (0, _jQuery2["default"])('#radweb_stripe_expiration_yr').val() > 0;
                        }.bind(this),
                        'stripeDateMonth': '#radweb_stripe_expiration_yr'
                    };

                    validateOptions.rules[pmc.options.ccFields.year] = {
                        'required': function () {
                            return (0, _jQuery2["default"])('#radweb_stripe_expiration').val() > 0;
                        }.bind(this),
                        'stripeDateYear': '#radweb_stripe_expiration'
                    };

                    validateOptions.rules['payment[method]'] = {
                        'required': true
                    };

                    this.form.validate(validateOptions);
                }
            });

            this.billingAddressSwitchChanged();
        }
    }, {
        key: "billingAddressSwitchChanged",
        value: function billingAddressSwitchChanged() {
            if (this.billingAddressRadios.filter(':checked').val() == '1') {
                this.billingAddressForm.slideUp();
                this.paymentMethodController.addressChanged(shippingAddress || this.addressController.options.emptyAddress);
            } else {
                this.billingAddressForm.slideDown();
            }
        }
    }, {
        key: "addressChanged",
        value: function addressChanged(e, address) {
            this.paymentMethodController.addressChanged(address);
        }
    }]);

    return PaymentController;
}();

exports["default"] = PaymentController;

},{"../lib/StripeValidators":11,"../lib/jQuery":12,"./AddressController":1,"./LoadingController":2,"./PaymentMethodController":5}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaymentMethodController = function () {
    function PaymentMethodController(loadingController, options) {
        _classCallCheck(this, PaymentMethodController);

        this.loadingController = loadingController;

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(PaymentMethodController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.doAutoSubmit = false;
            this.stripeAddress = {};
            this.options = {
                'formSel': '#payment-form',
                'paymentMethods': '#payment-methods',
                'paymentMethodsSel': 'input[name="payment[method]"]',
                'ccSavedOrNewSel': '#stripe_cards',
                'additionPaymentInfos': '.payment_additional',
                'ccCardFieldSel': '#payment_form_stripe',
                'ccFields': {
                    'number': 'payment[cc_number]',
                    'cvv': 'payment[cc_cid]',
                    'month': 'payment[cc_exp_month]',
                    'year': 'payment[cc_exp_year]'
                },
                'stripeToken': '#stripeToken',
                'cvvImage': '#cvvimage',
                'cvvToggle': '*[data-toggle="#cvvimage"]',
                'submitButton': '#payment-submit'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            var _this = this;

            this.form = (0, _jQuery2["default"])(this.options.formSel);
            this.paymentMethods = (0, _jQuery2["default"])(this.options.paymentMethods);
            this.stripeToken = (0, _jQuery2["default"])(this.options.stripeToken);
            this.ccFields = {};
            Object.keys(this.options.ccFields).forEach(function (key) {
                var val = _this.options.ccFields[key];
                _this.ccFields[key] = (0, _jQuery2["default"])(_this.toNameVal(val));
            });
            this.stripeCards = (0, _jQuery2["default"])(this.options.ccSavedOrNewSel);
            this.submitButton = (0, _jQuery2["default"])(this.options.submitButton);
        }
    }, {
        key: "toNameVal",
        value: function toNameVal(val) {
            return '*[name="' + val + '"]';
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.form.on('submit', this.formSubmit.bind(this));
            this.paymentMethods.delegate(this.options.paymentMethodsSel, 'change', this.paymentMethodChanged.bind(this));
            this.paymentMethods.delegate(this.options.ccSavedOrNewSel, 'change', this.ccMethodChanged.bind(this));

            this.paymentMethods.delegate(this.toNameVal(this.options.ccFields.number), 'change', this.cardInfoChanged.bind(this));
            this.paymentMethods.delegate(this.toNameVal(this.options.ccFields.cvv), 'change', this.cardInfoChanged.bind(this));
            this.paymentMethods.delegate(this.toNameVal(this.options.ccFields.month), 'change', this.ccMonthChanged.bind(this));
            this.paymentMethods.delegate(this.toNameVal(this.options.ccFields.year), 'change', this.ccYearChanged.bind(this));

            this.paymentMethods.delegate(this.options.cvvToggle, 'click', this.toggleCvvImage.bind(this));
        }
    }, {
        key: "init",
        value: function init() {
            this.hideCvvImage();
            this.paymentMethodChanged();
            this.cardInfoChanged();
            this.getSavedCreditCards();
        }
    }, {
        key: "paymentMethodChanged",
        value: function paymentMethodChanged() {
            var currentPaymentRadio = this.getSelectedRadio(this.options.paymentMethodsSel);
            this.hideAdditionalPaymentInfos();
            (0, _jQuery2["default"])('div[data-payment-additional-id="' + currentPaymentRadio.attr('id') + '"]').css({ 'display': 'block' });

            this.ccMethodChanged();
        }
    }, {
        key: "ccMethodChanged",
        value: function ccMethodChanged() {
            var mode = this.getCurrentCCMode() || '-1';
            var fields = (0, _jQuery2["default"])(this.options.ccCardFieldSel);
            if (!fields) return;

            if (mode == '-1') {
                fields.css({ 'display': 'block' });
            } else {
                fields.css({ 'display': 'none' });
            }
        }
    }, {
        key: "getCurrentPaymentMethod",
        value: function getCurrentPaymentMethod() {
            return this.getSelectedRadioVal(this.options.paymentMethodsSel);
        }
    }, {
        key: "getCurrentCCMode",
        value: function getCurrentCCMode() {
            return this.stripeCards.val();
        }
    }, {
        key: "getSelectedRadioVal",
        value: function getSelectedRadioVal(selector) {
            var selectedElement = this.getSelectedRadio(selector);
            if (selectedElement) {
                return selectedElement.val();
            }

            return false;
        }
    }, {
        key: "getSelectedRadio",
        value: function getSelectedRadio(selector) {
            return this.paymentMethods.find(selector + ':checked');
        }
    }, {
        key: "hideAdditionalPaymentInfos",
        value: function hideAdditionalPaymentInfos() {
            (0, _jQuery2["default"])(this.options.additionPaymentInfos).css({ 'display': 'none' });
        }
    }, {
        key: "isCardInfoComplete",
        value: function isCardInfoComplete() {
            return this.ccFields.number.hasClass('valid') && this.ccFields.cvv.hasClass('valid') && this.ccFields.month.hasClass('valid') && this.ccFields.year.hasClass('valid');
        }
    }, {
        key: "cardInfoChanged",
        value: function cardInfoChanged() {
            setTimeout(function () {
                if (!this.isCardInfoComplete()) return;

                this.createStripeToken();
            }.bind(this), 250);
        }
    }, {
        key: "createStripeToken",
        value: function createStripeToken() {
            this.loadingController.show('Creating secure payment token');

            Stripe.card.createToken({
                number: this.ccFields.number.val(),
                cvc: this.ccFields.cvv.val(),
                exp_month: this.ccFields.month.val(),
                exp_year: this.ccFields.year.val(),
                name: this.stripeAddress.name,
                address_line1: this.stripeAddress.address_line1,
                address_line2: this.stripeAddress.address_line2,
                address_city: this.stripeAddress.address_city,
                address_state: this.stripeAddress.address_state,
                address_zip: this.stripeAddress.address_zip,
                address_country: this.stripeAddress.address_country
            }, this.stripeTokenCreated.bind(this));
        }
    }, {
        key: "stripeTokenCreated",
        value: function stripeTokenCreated(status, resp) {
            if (resp.error) {
                alert(resp.error.message);
                this.submitButton.text('Please enter a valid Credit Card');
            } else {
                this.stripeInfo = resp;
                this.stripeToken.val(resp.id);

                this.loadingController.hide();
                if (this.doAutoSubmit) {
                    this.form.submit();
                }
            }
        }
    }, {
        key: "addressChanged",
        value: function addressChanged(address) {
            this.stripeAddress = address;
            this.cardInfoChanged();
        }
    }, {
        key: "formSubmit",
        value: function formSubmit(e) {
            var _this2 = this;

            if (this.getCurrentPaymentMethod() == 'radweb_stripe' && this.stripeToken.val().length < 3) {
                e.preventDefault();
                this.doAutoSubmit = true;

                return;
            }

            if (this.form.valid()) {
                Object.keys(this.ccFields).forEach(function (key) {
                    var el = _this2.ccFields[key];
                    el.prop('disabled', true);
                });
            }
        }
    }, {
        key: "toggleCvvImage",
        value: function toggleCvvImage() {
            if (this.cvvImageStat == 'hide') {
                this.showCvvImage();
            } else {
                this.hideCvvImage();
            }
        }
    }, {
        key: "hideCvvImage",
        value: function hideCvvImage() {
            this.cvvImageStat = 'hide';
            (0, _jQuery2["default"])(this.options.cvvImage).slideUp();
        }
    }, {
        key: "showCvvImage",
        value: function showCvvImage() {
            this.cvvImageStat = 'show';
            (0, _jQuery2["default"])(this.options.cvvImage).slideDown();
        }
    }, {
        key: "ccMonthChanged",
        value: function ccMonthChanged() {
            this.cardInfoChanged();
            if (this.ccFields.year.hasClass('valid') || this.ccFields.year.hasClass('error')) {
                this.ccFields.year.removeData("previousValue");
                this.ccFields.month.valid();
                this.ccFields.year.valid();
            }
        }
    }, {
        key: "ccYearChanged",
        value: function ccYearChanged() {
            this.cardInfoChanged();
            if (this.ccFields.month.hasClass('valid') || this.ccFields.month.hasClass('error')) {
                this.ccFields.month.removeData("previousValue");
                this.ccFields.year.valid();
                this.ccFields.month.valid();
            }
        }
    }, {
        key: "getSavedCreditCards",
        value: function getSavedCreditCards() {
            if (this.stripeCards.length == 0) return;

            _jQuery2["default"].ajax(this.stripeCards.data('saved-cards'), {
                'cache': false,
                'method': 'POST',
                'success': this.cardsLoaded.bind(this)
            });
        }
    }, {
        key: "cardsLoaded",
        value: function cardsLoaded(resp) {
            if (resp.status == 'ok') {
                this.stripeCards.html(resp.cards + this.stripeCards.html());
            }
        }
    }]);

    return PaymentMethodController;
}();

exports["default"] = PaymentMethodController;

},{"../lib/jQuery":12}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

var _AddressController = require("./AddressController");

var _AddressController2 = _interopRequireDefault(_AddressController);

var _ShippingMethodController = require("./ShippingMethodController");

var _ShippingMethodController2 = _interopRequireDefault(_ShippingMethodController);

var _LoginController = require("./LoginController");

var _LoginController2 = _interopRequireDefault(_LoginController);

var _LoadingController = require("./LoadingController");

var _LoadingController2 = _interopRequireDefault(_LoadingController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ShippingController = function () {
    function ShippingController(options) {
        _classCallCheck(this, ShippingController);

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(ShippingController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'form': '#shipping-form',
                'loginArea': '#login-area',
                'loginSwitch': '#customer-has-account'
            };

            _jQuery2["default"].extend(true, this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.form = (0, _jQuery2["default"])(this.options.form);
            this.loginArea = (0, _jQuery2["default"])(this.options.loginArea);
            this.loginSwitch = (0, _jQuery2["default"])(this.options.loginSwitch);
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.loginSwitch.on('change', this.loginSwitchChanged.bind(this));
        }
    }, {
        key: "init",
        value: function init() {
            this.loginSwitchChanged();

            var loadingController = new _LoadingController2["default"]({ 'form': this.options.form });

            this.addressController = new _AddressController2["default"](loadingController, {
                'initValidator': function initValidator() {
                    {
                        _jQuery2["default"].validator.setDefaults({
                            'onkeyup': false
                        });

                        var validateOptions = {
                            'rules': {
                                'login[password]': {
                                    required: '#customer-has-account:checked'
                                }
                            }
                        };

                        validateOptions.rules['shipping[postcode]'] = {
                            'required': function () {
                                return this.addressFields.country.val() == 'US';
                            }.bind(this),
                            'minlength': 3,
                            'remote': {
                                'url': this.addressFields.postcode.attr('data-validation-url'),
                                'type': 'post',
                                'data': {
                                    'state': function () {
                                        return this.addressFields.state.val();
                                    }.bind(this),
                                    'country': function () {
                                        return this.addressFields.country.val();
                                    }.bind(this)
                                },
                                complete: function () {
                                    this.somethingChanged();
                                }.bind(this)
                            }
                        };

                        this.form.validate(validateOptions);
                    }
                },
                'form': '#shipping-form',
                'addressSelect': '#shipping-address-select',
                'updateRates': true,
                'addressFields': {
                    'country': '#shipping-country_id'
                }
            });

            this.shippingMethodController = new _ShippingMethodController2["default"](loadingController);
            this.loginController = new _LoginController2["default"]();
        }
    }, {
        key: "loginSwitchChanged",
        value: function loginSwitchChanged() {
            if (this.loginSwitch.is(':checked')) {
                this.loginArea.slideDown();
            } else {
                this.loginArea.slideUp();
            }
        }
    }]);

    return ShippingController;
}();

exports["default"] = ShippingController;

},{"../lib/jQuery":12,"./AddressController":1,"./LoadingController":2,"./LoginController":3,"./ShippingMethodController":7}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ShippingMethodController = function () {
    function ShippingMethodController(loadingController, options) {
        _classCallCheck(this, ShippingMethodController);

        this.loadingController = loadingController;

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(ShippingMethodController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'zipcode': '#postcode',
                'state': '#region_id',
                'methodsContainer': '#shipping-rates',
                'totalsBlock': '#totals-block'
            };

            _jQuery2["default"].extend(true, this.options, options);

            this.lastMethod = null;
            this.lastZipCode = null;
            this.lastState = null;
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.methodsContainer = (0, _jQuery2["default"])(this.options.methodsContainer);
            this.totalsBlock = (0, _jQuery2["default"])(this.options.totalsBlock);
            this.zipcode = (0, _jQuery2["default"])(this.options.zipcode);
            this.state = (0, _jQuery2["default"])(this.options.state);
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.methodsContainer.delegate('input', 'change', this.methodChanged.bind(this));
            this.methodsContainer.on('shippingMethodsUpdated', this.methodsUpdated.bind(this));
        }
    }, {
        key: "init",
        value: function init() {}
    }, {
        key: "methodChanged",
        value: function methodChanged() {
            var selectedMethod = this.getSelectedShippingMethod();
            if (!selectedMethod) return;

            this.lastMethod = selectedMethod;
            this.lastZipCode = this.zipcode.val();
            this.lastState = this.state.val();

            this.loadingController.show('Apply shipping method.');

            if (this.lastAjaxRequest) {
                this.lastAjaxRequest.abort();
            }

            this.lastAjaxRequest = _jQuery2["default"].ajax(this.methodsContainer.data('ajax-url'), {
                'data': { 'shipping_method': selectedMethod },
                'cache': false,
                'method': 'POST',
                'success': this.updateComplete.bind(this)
            });
        }
    }, {
        key: "updateComplete",
        value: function updateComplete(resp) {
            this.loadingController.hide();
            if (resp.status == 'ok') {
                this.totalsBlock.html(resp.summary);
            } else {
                this.loadingController.setLoadingText(resp.message);
            }
        }
    }, {
        key: "methodsUpdated",
        value: function methodsUpdated() {
            var selectedMethod = this.getSelectedShippingMethod();
            var zipCode = this.zipcode.val();
            var state = this.state.val();
            if (this.lastMethod == selectedMethod && this.lastZipCode == zipCode && this.lastState == state) {
                return;
            }

            this.methodChanged();
        }
    }, {
        key: "getSelectedShippingMethod",
        value: function getSelectedShippingMethod() {
            return this.methodsContainer.find('input:checked').val();
        }
    }]);

    return ShippingMethodController;
}();

exports["default"] = ShippingMethodController;

},{"../lib/jQuery":12}],8:[function(require,module,exports){
"use strict";

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

var _ShippingController = require("./ShippingController");

var _ShippingController2 = _interopRequireDefault(_ShippingController);

var _PaymentController = require("./PaymentController");

var _PaymentController2 = _interopRequireDefault(_PaymentController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

(0, _jQuery2["default"])(document).ready(function () {
    var body = (0, _jQuery2["default"])(document.body);

    if (body.hasClass('unlooped-checkout-shipping-index')) {
        var sc = new _ShippingController2["default"]();
    }

    if (body.hasClass('unlooped-checkout-payment-index')) {
        var pc = new _PaymentController2["default"]();
    }

    if (body.hasClass('unlooped-checkout-summary-index')) {}

    if (body.hasClass('unlooped-checkout-success-index')) {}
});

},{"../lib/jQuery":12,"./PaymentController":4,"./ShippingController":6}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProductController = function () {
    function ProductController(options) {
        _classCallCheck(this, ProductController);

        this.loadDefaults(options);
        this.loadElements();
        this.loadEvents();

        this.init();
    }

    _createClass(ProductController, [{
        key: "loadDefaults",
        value: function loadDefaults(options) {
            this.options = {
                'col1': '#conf-number-2',
                'col2': '#conf-number-3',
                'col3': '#conf-number-4'
            };

            Object.assign(this.options, options);
        }
    }, {
        key: "loadElements",
        value: function loadElements() {
            this.col1 = (0, _jQuery2["default"])(this.options.col1);
            this.col2 = (0, _jQuery2["default"])(this.options.col2);
            this.col3 = (0, _jQuery2["default"])(this.options.col3);

            this.col1Fields = this.col1.find('select');
            this.col2Fields = this.col2.find('select');
            this.col3Fields = this.col3.find('input');
        }
    }, {
        key: "loadEvents",
        value: function loadEvents() {
            this.col1Fields.on('change', this.col1Changed.bind(this));
            this.col2Fields.on('change', this.col2Changed.bind(this));
            this.col3Fields.on('change', this.col3Changed.bind(this));
        }
    }, {
        key: "init",
        value: function init() {}
    }, {
        key: "col1Changed",
        value: function col1Changed() {
            var checkedCount = this.getCol1CheckedCount();

            if (checkedCount > 0) {
                this.col2Fields.prop('disabled', true);
                this.col3Fields.prop('disabled', true);
            } else {
                this.col2Fields.prop('disabled', false);
                this.col3Fields.prop('disabled', false);
            }

            if (checkedCount >= 3) {
                var unchecked = this.getCol1Unchecked();
                unchecked.prop('disabled', true);
            } else {
                this.col1Fields.prop('disabled', false);
            }
        }
    }, {
        key: "col2Changed",
        value: function col2Changed() {
            if (this.isFirstOptionSelected(this.col2Fields[0])) {
                this.col1Fields.prop('disabled', false);
                this.col3Fields.prop('disabled', false);
            } else {
                this.col1Fields.prop('disabled', true);
                this.col3Fields.prop('disabled', true);
            }
        }
    }, {
        key: "col3Changed",
        value: function col3Changed() {
            if ((0, _jQuery2["default"])(this.col3Fields[0]).is(':checked')) {
                this.col1Fields.prop('disabled', true);
                this.col2Fields.prop('disabled', true);
            } else {
                this.col1Fields.prop('disabled', false);
                this.col2Fields.prop('disabled', false);
            }
        }
    }, {
        key: "isFirstOptionSelected",
        value: function isFirstOptionSelected(select) {
            var first = (0, _jQuery2["default"])((0, _jQuery2["default"])(select).find('option')[0]);
            return first.is(':checked');
        }
    }, {
        key: "getCol1CheckedCount",
        value: function getCol1CheckedCount() {
            var _this = this;

            var result = 0;
            this.col1Fields.each(function (idx, el) {
                if (!_this.isFirstOptionSelected(el)) {
                    result++;
                }
            });

            return result;
        }
    }, {
        key: "getCol1Unchecked",
        value: function getCol1Unchecked() {
            var _this2 = this;

            var result = null;
            this.col1Fields.each(function (idx, el) {
                if (_this2.isFirstOptionSelected(el)) {
                    result = (0, _jQuery2["default"])(el);
                }
            });

            return result;
        }
    }]);

    return ProductController;
}();

exports["default"] = ProductController;

},{"../lib/jQuery":12}],10:[function(require,module,exports){
"use strict";

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

var _ProductController = require("./ProductController");

var _ProductController2 = _interopRequireDefault(_ProductController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

(0, _jQuery2["default"])(document).ready(function () {
    var body = (0, _jQuery2["default"])(document.body);

    if (body.hasClass('catalog-product-view')) {
        var pc = new _ProductController2["default"]();
    }
});

},{"../lib/jQuery":12,"./ProductController":9}],11:[function(require,module,exports){
"use strict";

var _jQuery = require("../lib/jQuery");

var _jQuery2 = _interopRequireDefault(_jQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

_jQuery2["default"].validator.addMethod("stripeCC", function (value, element) {
    return Stripe.card.validateCardNumber(value);
}, "Please enter a valid credit card number");

_jQuery2["default"].validator.addMethod("stripeCVV", function (value, element) {
    return Stripe.card.validateCVC(value);
}, "Please enter a valid CVV number");

_jQuery2["default"].validator.addMethod("stripeDateMonth", function (value, element, counterPart) {
    var cp = (0, _jQuery2["default"])(counterPart);
    if (!cp.hasClass('valid') && !cp.hasClass('error')) return true;

    var dateValid = false;

    if (value > 0 && cp.val() > 0) {
        dateValid = Stripe.card.validateExpiry(value, cp.val());
    }

    return dateValid;
}, "Please enter a valid month");

_jQuery2["default"].validator.addMethod("stripeDateYear", function (value, element, counterPart) {
    var cp = (0, _jQuery2["default"])(counterPart);
    if (!cp.hasClass('valid') && !cp.hasClass('error')) return true;

    var dateValid = false;

    if (value > 0 && cp.val() > 0) {
        dateValid = Stripe.card.validateExpiry(cp.val(), value);
    }

    return dateValid;
}, "Please enter a valid year");

},{"../lib/jQuery":12}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = jQuery;

},{}]},{},[8,10]);
